import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Container, Box, Heading } from "../components/ui"
import "./form.css"

export default function wpPost(props) {
  const { wpPost } = props.data

  return (
    <Layout {...wpPost}>
      <Box paddingY={5}>
        <Container width="narrow">
          <Heading as="h1">{wpPost.title}</Heading>
          <div className="pagehtml"
            dangerouslySetInnerHTML={{
              __html: wpPost.content,
            }}
          />
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query wpPost($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      slug
      featuredImage {
        node {
          gatsbyImage(quality: 10, width: 900)
          uri
        }
      }
      content
    }
  }
`


